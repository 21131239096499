import tabifyForWizard from 'core/hoc/tabifyForWizard';
import React, { useEffect, useState } from 'react';
import validations from './validations';
import withFormContext from 'core/hoc/withFormContext';
import { Option, OptionsGroup, PaymentRecipientsTabProps } from './types';
import { useTranslation } from 'react-i18next';

import AgendaIcon from 'components/AgendaIcon';
import Field from 'components/Form/Field';
import FormFieldset from 'components/Form/Fieldset';
import FormStudentsSelector from 'components/Form/StudentsSelector';
import GroupSelector from 'components/Form/GroupSelector';
import FinancialResponsibleConfiguration from 'components/Payments/PaymentsContainer/FormSections/FinancialResponsibleConfiguration';
import Title from 'components/WizardForm/Title';

import * as S from './styles';

const RADIOS = [
  {
    labelText: 'Enviar para alunos',
    value: 'student',
  },
  {
    labelText: 'Enviar para turmas',
    value: 'classroom',
  },
];

const PaymentRecipientsTab = ({
  formContext: {
    formMeta: { classrooms, status },
  },
  formContext: {
    updateAttribute,
    changeMeta,
    action,
    form: { classroom_ids, sent_kind },
  },
}: PaymentRecipientsTabProps) => {
  const [recipientChosen, setRecipientChosen] = useState('student');

  const { t } = useTranslation('payments');

  const disabled =
    action !== 'new' &&
    !['pending', 'rejected', 'not_started', 'active'].includes(status);

  useEffect(() => {
    if (sent_kind !== recipientChosen) {
      setRecipientChosen(sent_kind);
    }
  }, [sent_kind]);

  const updateSelectorColumns = (selectedValues: Array<string>) => {
    updateAttribute('classroom_ids', selectedValues);
    changeMeta('changedClassrooms', true);
  };

  const optionComponent = (option: Option) => (
    <span>{option?.attributes?.name}</span>
  );

  const groupComponent = (
    optionGroup: OptionsGroup,
    // eslint-disable-next-line no-unused-vars
    onOptionGroupSelect: (option: OptionsGroup) => void
  ) => {
    return (
      <span
        className="opt-group"
        onClick={() => onOptionGroupSelect(optionGroup)}
      >
        <strong>{optionGroup?.label}</strong>
      </span>
    );
  };

  const EmptyStateComponent = () => (
    <div className="empty">
      <AgendaIcon name="user-group" size="large" />
      <div className="empty-message">
        {t('form_section.recipients_empty_state')}
      </div>
    </div>
  );

  const searchOptions = ({ options, searchTerm }) => {
    const filteredOptions = [];

    options?.forEach((classroomGroup: OptionsGroup) => {
      const matches = classroomGroup?.options?.filter((opt: Option) =>
        opt?.attributes?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );

      if (matches?.length) {
        filteredOptions.push({
          label: classroomGroup?.label,
          options: matches,
        });
      }
    });

    return filteredOptions;
  };

  return (
    <S.Wrapper data-testId="PaymentRecipientsTabWrapper">
      <FormFieldset>
        <div className="row">
          <div className="information-wrapper">
            <FinancialResponsibleConfiguration />
          </div>
        </div>
      </FormFieldset>
      {!disabled && (
        <div className="row">
          <div className="information-wrapper">
            <Title name={t('form_section.select_destinatons')} />
            <div className="stepInfo text-center">
              <p>
                {t('form_section.select_student_class')}{' '}
                <strong>{t('common.or')}</strong>{' '}
                {t('form_section.select_student_class_message')}
              </p>
            </div>
          </div>
          <div className="RadioGroup">
            <Field type="radio" attributeName="sent_kind" radios={RADIOS} />
          </div>
        </div>
      )}
      {disabled && (
        <div className="row">
          <div className="stepInfo text-center margin-bottom">
            <p>{t('form_section.not_add_new_destination')}</p>
          </div>
        </div>
      )}
      {recipientChosen === 'student' && (
        <FormStudentsSelector
          attributeName="classroom_with_student_profile_ids"
          disabled={disabled}
          selectPlaceholder={t('form_section.select_unique_student_class')}
        />
      )}
      {recipientChosen === 'classroom' && (
        <GroupSelector
          options={classrooms}
          selectedValues={classroom_ids}
          onChange={updateSelectorColumns}
          optionComponent={optionComponent}
          groupComponent={groupComponent}
          searchPlaceholder={t('form_section.find_student_class')}
          optionAccessor={(option: Option) => option?.id}
          groupAccessor={(group: OptionsGroup) => group?.label}
          counterLabel={(length: number) =>
            length === 1
              ? t('form_section.student_class')
              : t('form_section.student_classes')
          }
          search={searchOptions}
          lockSelected={disabled}
          lockOptions={disabled}
          emptyState={EmptyStateComponent}
        />
      )}
    </S.Wrapper>
  );
};

export default tabifyForWizard({
  title: '2. Destinatários',
  validations,
})(withFormContext(PaymentRecipientsTab));
