import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    width: 600px;
    margin-left: auto;
    margin-right: auto;

    > fieldset {
      width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    .financial-responsible-configuration .Title {
      padding: 0;
    }

    .Title {
      padding: 0 15px;
    }

    .stepInfo {
      text-align: left;
      padding: 0 15px;
    }

    .GroupSelector {
      margin-right: auto;
      width: auto;
    }

    .FormStudentsSelector > .icon {
      margin: 0 auto;
    }

    @media (max-width: ${breakpoints.tabletMD}) {
      width: 100%;
      margin-top: 10px;
      padding: 0 15px;
    }

    @media (max-width: ${breakpoints.desktopLG}) {
      .AgendaIcon.icon-ae-swap-horizontal {
        transform: rotate(90deg);
      }
    }
  `}
`;
