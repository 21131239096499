import React, { useEffect, useState } from 'react';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { titleMobile } from 'core/utils/changeText';

import FormFieldset from 'components/Form/Fieldset';
import Field from 'components/Form/Field';
import { Text } from '@agendaedu/ae-web-components';

import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';
import { AIPromptProvider } from 'core/contexts/AIPrompt';
import { AIPromptFormModal } from 'components/AIPromptFormModal';
import AIButton from 'components/AIPromptFormModal/components/AIButton';

import validateTextAreaReady from 'core/lib/FormValidator/validators/textAreaReady';

import './style.scss';
import validations from './validations';

const SurveyFormInformationsTab = ({
  formContext: {
    form: { title, description, time_end, date_end, kind },
    changeMeta,
    updateAttribute,
  },
}) => {
  const [isOpenDescriptionAIModal, setIsOpenDescriptionAiModal] =
    useState(false);

  const { isActiveFlag: isActivePromptAI } = useLaunchDarkly({
    flag: 'PROMPT_MODAL_AI',
  });

  const nextStepDisabled = [
    title,
    date_end,
    time_end,
    description,
    validateTextAreaReady(description),
  ].some((value) => !value);

  const placeholder = {
    poll: 'ex: Pesquisa de Satisfação',
    authorization: 'ex: Passeio ao Zoológico',
  }[kind];

  useEffect(() => {
    changeMeta('nextStepDisabled', nextStepDisabled);
  }, [changeMeta, nextStepDisabled]);

  return (
    <div className="SurveyFormInformationsTab">
      <FormFieldset>
        <div className="row">
          <Field
            label="Título*"
            placeholder={placeholder}
            attributeName="title"
          />
        </div>

        <div className="row">
          {isActivePromptAI && (
            <div className="mb-3 mt-2">
              <div className="col-xs-12 col-md-12 vertical mb-2">
                <AIButton onPress={() => setIsOpenDescriptionAiModal(true)} />
              </div>
            </div>
          )}

          <Field
            label="Descrição*"
            quillProps={{
              onChange: (value) =>
                description !== value && updateAttribute('description', value),
              value: description,
            }}
            type="textArea"
            useImage
          />
        </div>

        <div className="row double-input time-inputs">
          <Field
            label="Data limite para respostas*"
            placeholder="Selecionar data"
            attributeName="date_end"
            type="date"
          />

          <Field
            label="Hora limite para respostas*"
            attributeName="time_end"
            type="time"
            placeholder="Selecionar hora"
          />
        </div>

        <Text variant="body-regular-12" color="neutral.gray1">
          *Campos obrigatórios
        </Text>

        {isActivePromptAI && (
          <AIPromptProvider feature={`survey-${kind}`}>
            <AIPromptFormModal
              isOpen={isOpenDescriptionAIModal}
              onClose={() => setIsOpenDescriptionAiModal(false)}
              onInsert={(value) => updateAttribute('description', value)}
            />
          </AIPromptProvider>
        )}
      </FormFieldset>
    </div>
  );
};

SurveyFormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: titleMobile('1 Informações'),
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(SurveyFormInformationsTab));
