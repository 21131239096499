export const flags = Object.freeze({
  RESEND_BILLET_EMAIL: 'time-inadimplencia.resend.billet',
  NEGOTIATION_IS_ACTIVE: 'time-inadimplencia.negotiation',
  NEGOTIATION_TAB: 'time-inadimplencia.negotiation.tab',
  NEGOTIATION_TAB_CHECKBOX: 'time-inadimplencia.negotiation.tab.checkbox',
  PIX_ISSUED_AT_SCHOOL: 'time-core-banking-pix-issued-at-school',
  PIX_ISSUED_AT_SCHOOL_PRODUCT_AND_NEGOTIATION:
    'time-core-banking-pix-issued-at-school-product-and-negotiation',
  SCHOOL_PRODUCT_CANCELLATION: 'time-pagamentos.school.product.cancellation',
  SCHOOL_PRODUCT_PAID_AT_SCHOOL: 'time-pagamentos.school.product.paidAtSchool',
  ENROLLMENT_CANCELLATION: 'time-pagamentos.enrollment.cancellation',
  ENROLLMENT_PAID_AT_SCHOOL: 'time-pagamentos.enrollment.paidAtSchool',
  RECURRENT_EDIT_IN_BATCH: 'time-pagamentos-recurrent-edit-in-batch',
  CANCEL_WITH_BOND: 'time-pagamentos-cancellation-with-bond',
  ABSORB_TAX: 'time-pagamentos-absorb-tax',
  CREDIT_CARD_DISCOUNT: 'pagamentos-descontos-no-cartao-em-recorrencia',
  SHOP_OPTIONS: 'time-pagamentos.shop.tamanhos',
  GROUPS_V2: 'time-comunicacao-groups-v2',
  DIARY_FORM_BY_SECTION: 'time-comunicacao.diary-form-by-sections',
  NEW_TICKET_BY_SCHOOL: 'time-comunicacao-new-ticket-by-school',
  REDE_LIUS_GUIA_DE_EMBARQUE: 'time-comunicacao.rede-lius.guia-de-embarque',
  CHANNEL_SUBJECT: 'time-comunicacao-campo-assunto',
  NEW_CHAT: 'time-comunicacao-nova-conversa',
  CHANNEL_ENHANCEMENTS: 'time-comunicacao-melhorias-em-mensagens',
  PANEL_HUBSTORE: 'time-conexoes.hub-store.explore',
  PROMPT_MODAL_AI: 'time-comunicacao-prompt-modal-ai',
});
