import React, { useEffect, useState } from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormFieldset from 'components/Form/Fieldset';
import Field from 'components/Form/Field';
import Title from 'components/WizardForm/Title';
import FileField from 'components/Form/FileField';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import CommonLabel from 'components/CommonLabel';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import EventContext from '../../eventContext';

import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';
import { AIPromptProvider } from 'core/contexts/AIPrompt';
import { AIPromptFormModal } from 'components/AIPromptFormModal';
import AIButton from 'components/AIPromptFormModal/components/AIButton';

import './style.scss';
import validations from './validations';

const RADIOS = [
  {
    labelText: 'Ambos',
    value: 'both',
  },
  {
    labelText: 'Responsáveis',
    value: 'responsibles',
  },
  {
    labelText: 'Alunos',
    value: 'students',
  },
];

const EventFormInformationsTab = ({
  formContext: { form, formMeta, changeMeta, updateAttribute },
}) => {
  const eventContext = React.useContext(EventContext);

  const [isOpenDescriptionAIModal, setIsOpenDescriptionAiModal] =
    useState(false);

  const { isActiveFlag: isActivePromptAI } = useLaunchDarkly({
    flag: 'PROMPT_MODAL_AI',
  });

  const { token, googleOAuthSuccess, signIn, setGoogleOAuthSuccess } =
    eventContext;

  const onChangeKind = () => {
    updateAttribute(
      'kind',
      form.kind === 'confirmation' ? 'attendance' : 'confirmation'
    );
  };

  const onChangeConference = (event) => {
    setGoogleOAuthSuccess(true);

    if (!form.has_conference) {
      signIn(event);
    }

    updateAttribute('has_conference', !form.has_conference);
  };

  useEffect(() => {
    if (token) updateAttribute('google_oauth_token', token);
  }, [token]);

  // Clean students list from EventForm/RecipientsTab when change persona_type
  useEffect(() => {
    changeMeta('classroom_with_student_profile_ids_ClassroomStudents', []);
  }, [form.persona_type]);

  return (
    <div className="EventFormInformationsTab">
      <FormFieldset>
        <div className="row">
          <Field type="image" label="Imagem de capa" attributeName="image" />
        </div>

        <div className="row">
          <Field
            type="radio"
            label="Enviar para*"
            attributeName="persona_type"
            radios={RADIOS}
            disabled={!formMeta.canEditClassrooms}
          />

          <Field label="Título*" placeholder="" attributeName="title" />
        </div>

        <div className="row double-input">
          <Field
            label="Data*"
            placeholder=""
            attributeName="date"
            type="date"
          />
        </div>

        <div className="row double-input time-inputs">
          <Field
            label="Horário de início*"
            attributeName="start_at"
            type="time"
            placeholder="Selecionar horário"
          />

          <Field
            label="Horário de término"
            attributeName="end_at"
            type="time"
            placeholder="Selecionar horário"
          />
        </div>

        <div className="row">
          <Field label="Local" placeholder="" attributeName="location" />

          {isActivePromptAI && (
            <div className="mb-3 mt-2">
              <div className="col-xs-12 col-md-12 vertical mb-2">
                <AIButton onPress={() => setIsOpenDescriptionAiModal(true)} />
              </div>
            </div>
          )}
          <Field
            label="Descrição*"
            attributeName="description"
            quillProps={{
              onChange: (value) =>
                form.description !== value &&
                updateAttribute('description', value),
              value: form.description,
            }}
            type="textArea"
            useImage
          />
        </div>

        <div className="row">
          <div className="col-md-12 col-xs-12">
            <FileField
              attributeName="attachments"
              googleClientId={formMeta.googleClientId}
              useGoogleDrive
            />
          </div>
        </div>

        <Title name="Configurações adicionais" />

        <div className="row">
          <div className="col-md-12 toggle-switch">
            <div className="col-md-10">
              <div className="form-group">
                <span className="switch-option">Confirmação de presença</span>
                <p className="switch-description">
                  Habilite a confirmação de presença caso deseje as opções de
                  respostas sim, não e talvez, para os responsáveis e alunos.
                </p>
              </div>
            </div>

            <div className="col-md-2">
              <ToggleSwitch
                toggled={form.kind === 'attendance'}
                onChange={onChangeKind}
                disabled={!formMeta.canEditKind}
              />
            </div>
          </div>

          {form.kind === 'attendance' && (
            <div className="col-md-12">
              <div className="row double-input">
                <Field
                  label="Data limite de confirmação*"
                  placeholder=""
                  attributeName="confirm_until"
                  type="date"
                />
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-12 toggle-switch">
            <div className="col-md-10">
              <div className="form-group">
                <span className="switch-option">Adicionar videochamada</span>
                <p className="switch-description">
                  Habilite essa opção para criar uma sala no Google Hangouts
                  Meet e disponibilizar o link para que os participantes acessem
                  o evento online.
                </p>
              </div>
            </div>

            <div className="col-md-2">
              <ToggleSwitch
                toggled={form.has_conference}
                onChange={onChangeConference}
                disabled={!formMeta.canEditConference}
              />
            </div>
          </div>

          {form.has_conference && (
            <div className="col-md-12">
              <CommonLabel variation="info">
                É necessário entrar com a sua conta do Google para criar o link
                da videochamada, que será disponibilizado para alunos e
                responsáveis.
              </CommonLabel>
            </div>
          )}
        </div>

        <div className="GoogleErrorAlert row">
          <div className="col-md-12">
            <span className="text-danger">
              {!googleOAuthSuccess &&
                'Ocorreu um erro ao tentar autenticar com o Google.'}
            </span>
          </div>
        </div>

        <span className="mandatory-fields">
          * Campos de preenchimento obrigatório
        </span>

        {isActivePromptAI && (
          <AIPromptProvider feature="event">
            <AIPromptFormModal
              isOpen={isOpenDescriptionAIModal}
              onClose={() => setIsOpenDescriptionAiModal(false)}
              onInsert={(value) => updateAttribute('description', value)}
            />
          </AIPromptProvider>
        )}
      </FormFieldset>
    </div>
  );
};

EventFormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '1. Informações',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(EventFormInformationsTab));
