import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import validations from './validations';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';
import { validateFormErrors } from 'core/helper/handouts';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import {
  Box,
  Button,
  ImageUploader,
  Select,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { Modal as NewCategoryModal } from 'components/Handouts/Modal';
import Field from 'components/Form/Field';
import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';
import AIButton from 'components/AIPromptFormModal/components/AIButton';
import { AIPromptFormModal } from 'components/AIPromptFormModal';

import * as S from './styles';

import { Props } from './types';
import { AIPromptProvider } from 'core/contexts/AIPrompt';

const Informations: React.FC<Props> = ({
  appContext: {
    policies: { can_write_handout_category: canWriteHandoutCategory },
  },
  formContext: {
    form: { templateTitle, title, description, categoryId, coverImage },
    updateAttribute,
    hasErrorOnAttribute,
    getFormValidationResult,
  },
}) => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const [isCreatedCategory, setIsCreatedCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [isOpenAIModal, setIsOpenAiModal] = useState(false);

  const {
    fetchCategoriesRequest,
    toggleNewCategoryModal,
    createNewCategoryRequest,
  } = actionHandouts;

  const { isActiveFlag: isActiveHandoutsAI } = useLaunchDarkly({
    flag: 'PROMPT_MODAL_AI',
  });

  const { handoutCategories, showNewCategoryModal } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const handleChangeCategory = useCallback(
    ({ label, value }) => {
      !value
        ? setNewCategory(
            label
              .toLowerCase()
              .replace(/(^\w{1})|(\s+\w{1})/g, (words: string) =>
                words.toUpperCase()
              )
          )
        : setNewCategory('');

      updateAttribute('categoryId', value);
    },
    [updateAttribute]
  );

  const handleChangeFormAttributes = useCallback(
    (attributeName: string, value: string | File): void => {
      updateAttribute(attributeName, value);
    },
    [updateAttribute]
  );

  const handleGetErrors = (name: string) =>
    validateFormErrors(name, getFormValidationResult, hasErrorOnAttribute);

  const handleToggleNewCategoryModal = useCallback((): void => {
    if (!canWriteHandoutCategory) return;

    dispatch(toggleNewCategoryModal());
  }, [canWriteHandoutCategory, dispatch, toggleNewCategoryModal]);

  const handleNewCategory = useCallback(
    (categoryName: string) => {
      dispatch(createNewCategoryRequest(categoryName));
      handleToggleNewCategoryModal();
      setIsCreatedCategory(true);
    },
    [createNewCategoryRequest, dispatch, handleToggleNewCategoryModal]
  );

  const handleFetchCategories = useCallback((): void => {
    dispatch(fetchCategoriesRequest());
  }, [dispatch, fetchCategoriesRequest]);

  useEffect(() => {
    handleFetchCategories();
  }, [handleFetchCategories]);

  useEffect(() => {
    const newCreatedCategory = handoutCategories.categories.find(
      (category) => category.label === newCategory
    );

    if (isCreatedCategory && newCreatedCategory) {
      updateAttribute('categoryId', newCreatedCategory.value);
      setIsCreatedCategory(false);
    }
  }, [
    handoutCategories,
    isCreatedCategory,
    newCategory,
    setIsCreatedCategory,
    updateAttribute,
  ]);

  return (
    <>
      <S.StepWrapper>
        <Text mt={22} variant="title-bold-20" color={'neutral.black'}>
          {t('templates.form.information_step_title')}
        </Text>

        <Box mt={16}>
          <TextField
            data-testid="input-template-title"
            clearable
            fullWidth
            label={t('templates.form.title_input_label')}
            placeholder={t('templates.form.title_input_placeholder')}
            value={templateTitle}
            onChange={({ target: { value } }) =>
              handleChangeFormAttributes('templateTitle', value)
            }
            errorMessage={t(
              `form_validation.${
                handleGetErrors('templateTitle').errorMessages
              }`
            )}
            error={handleGetErrors('templateTitle').hasError}
          />
          <Text
            data-testid="information-alert"
            lineHeight="lg"
            color={'neutral.gray1'}
            mt={20}
            variant="body-regular-16"
          >
            {t('templates.form.title_input_information')}
          </Text>
        </Box>
        <S.RowWrapper>
          <ImageUploader
            data-testid="image-uploader"
            label={t('templates.form.upload_image_label')}
            value={coverImage}
            onChange={(file: File) =>
              handleChangeFormAttributes('coverImage', file)
            }
          />

          <TextField
            data-testid="input-handout-title"
            clearable
            fullWidth
            value={title}
            label={t('templates.form.title_handout_label')}
            placeholder={t('templates.form.title_handout_placeholder')}
            onChange={({ target: { value } }) =>
              handleChangeFormAttributes('title', value)
            }
            errorMessage={t(
              `form_validation.${handleGetErrors('title').errorMessages}`
            )}
            error={handleGetErrors('title').hasError}
          />

          <Select
            data-testid="category-select"
            label={t('new_handout_form.steps.information.category.label_text')}
            placeholder={t(
              'new_handout_form.steps.information.category.placeholder_text'
            )}
            value={categoryId}
            options={handoutCategories.categories}
            onChange={handleChangeCategory}
            onActionSubmit={handleToggleNewCategoryModal}
            isSearchable
            noOptionsMessage={t(
              canWriteHandoutCategory
                ? 'new_handout_form.steps.information.category.empty_options_text'
                : 'new_handout_form.steps.information.category.empty_options_without_permition_text'
            )}
            fullWidth
            clearable
            errorText={
              handleGetErrors('categoryId').hasError &&
              t('form_validation.required_field')
            }
          />

          {isActiveHandoutsAI && (
            <div>
              <AIButton onPress={() => setIsOpenAiModal(true)} />
            </div>
          )}

          <>
            <Field
              label={t('templates.form.description_handout_label')}
              quillProps={{
                onChange: (value: string) =>
                  description !== value &&
                  updateAttribute('description', value),
                value: description,
              }}
              type="textArea"
              useImage
            />

            {handleGetErrors('description').hasError && (
              <Text variant="label-regular-14" color="context.danger.default">
                {t('form_validation.required_field')}
              </Text>
            )}
          </>

          <S.RequiredFields data-testid="required-field">
            {t('new_handout_form.steps.information.required_field_text')}
          </S.RequiredFields>
        </S.RowWrapper>
      </S.StepWrapper>

      <NewCategoryModal.Root
        dataTestId="new-category-modal"
        isOpen={showNewCategoryModal}
        handleToggleModal={handleToggleNewCategoryModal}
        title={t('modals.create_category.title')}
      >
        <NewCategoryModal.Content>
          {t('modals.create_category.description')}
        </NewCategoryModal.Content>
        <NewCategoryModal.Actions>
          <Button variant="secondary" onClick={handleToggleNewCategoryModal}>
            {t('modals.create_category.close_button_text')}
          </Button>
          <Button onClick={() => handleNewCategory(newCategory)}>
            {t('modals.create_category.submit_button_text')}
          </Button>
        </NewCategoryModal.Actions>
      </NewCategoryModal.Root>

      {isActiveHandoutsAI && (
        <AIPromptProvider feature="handout">
          <AIPromptFormModal
            isOpen={isOpenAIModal}
            onClose={() => setIsOpenAiModal(false)}
            onInsert={(value) => updateAttribute('description', value)}
          />
        </AIPromptProvider>
      )}
    </>
  );
};
export default tabifyForWizard({
  title: '1 Informações',
  validations,
})(withAppContext(withFormContext(Informations)));
