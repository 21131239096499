import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';
import {
  Button,
  Box,
  Select,
  TextField,
  ImageUploader,
  Text,
} from '@agendaedu/ae-web-components';

import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import HandoutContext from 'core/contexts/Handout';
import { validateFormErrors } from 'core/helper/handouts';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import Field from 'components/Form/Field';
import FileField from 'components/Form/FileField';
import { Modal as NewCategoryModal } from 'components/Handouts/Modal';

import { GeneralInformationStepProps, OptionProps } from './types';
import { FileFieldSkeleton } from './skeleton';

import validations from './validations';

import { AIPromptProvider } from 'core/contexts/AIPrompt';
import { AIPromptFormModal } from '../../../../components/AIPromptFormModal';
import AIButton from 'components/AIPromptFormModal/components/AIButton';

import * as S from './styles';

const GeneralInformationStep = ({
  appContext: {
    policies: { can_write_handout_category: canWriteHandoutCategory },
  },
  formContext: {
    form: {
      title,
      categoryId,
      description,
      coverImage,
      directUploadAttachments,
    },
    formMeta: { googleClientId, duplicatedUploadAttachments, action },
    updateAttribute,
    hasErrorOnAttribute,
    getFormValidationResult,
    changeMeta,
  },
}: GeneralInformationStepProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts']);
  const { token } = useContext(HandoutContext);

  const [newTitle, setNewTitle] = useState<Partial<string>>(title);
  const [category, setCategory] = useState<Partial<string>>(categoryId);
  const [newCategory, setNewCategory] = useState('');
  const [isCreatedCategory, setIsCreatedCategory] = useState(false);
  const [isOpenAIModal, setIsOpenAiModal] = useState(false);

  const {
    current,
    handoutCategories,
    isLoadingDuplicateHandout,
    showNewCategoryModal,
  } = useSelector((state: HandoutState) => state.handouts);

  const {
    createNewCategoryRequest,
    fetchCategoriesRequest,
    toggleNewCategoryModal,
  } = actionHandouts;

  const { isActiveFlag: isActiveHandoutsAI } = useLaunchDarkly({
    flag: 'PROMPT_MODAL_AI',
  });

  const handleToggleNewCategoryModal = useCallback(() => {
    if (!canWriteHandoutCategory) return;

    dispatch(toggleNewCategoryModal());
  }, [dispatch, toggleNewCategoryModal, canWriteHandoutCategory]);

  const handleNewCategory = useCallback(
    (categoryName: string) => {
      dispatch(createNewCategoryRequest(categoryName));
      handleToggleNewCategoryModal();
      setIsCreatedCategory(true);
    },
    [dispatch, handleToggleNewCategoryModal, createNewCategoryRequest]
  );

  const handleFetchCategories = useCallback(() => {
    dispatch(fetchCategoriesRequest());
  }, [dispatch, fetchCategoriesRequest]);

  const handleTitleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setNewTitle(value);
    updateAttribute('title', value);
  };

  const handleCategoryChange = ({ label, value }: OptionProps) => {
    !value
      ? setNewCategory(
          label
            .toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (words) => words.toUpperCase())
        )
      : setNewCategory('');

    setCategory(value);
    updateAttribute('categoryId', value);
  };

  const handleImageChange = (image: File) => {
    updateAttribute('coverImage', image);
  };

  useEffect(() => {
    handleFetchCategories();
  }, []);

  useEffect(() => {
    if (token) updateAttribute('google_oauth_token', token);
  }, [updateAttribute, token]);

  useEffect(() => {
    const newCreatedCategory = handoutCategories.categories.find(
      (category) => category.label === newCategory
    );

    if (isCreatedCategory && newCreatedCategory) {
      setCategory(newCreatedCategory.value);
      updateAttribute('categoryId', newCreatedCategory.value);
      setIsCreatedCategory(false);
    }
  }, [
    handoutCategories,
    isCreatedCategory,
    newCategory,
    setIsCreatedCategory,
    updateAttribute,
  ]);

  useEffect(() => {
    if (
      current?.directUploadAttachments[0]?.duplicated &&
      action === 'duplicate' &&
      !duplicatedUploadAttachments
    ) {
      changeMeta('duplicatedUploadAttachments', true);
      updateAttribute(
        'directUploadAttachments',
        current.directUploadAttachments
      );
    }
  }, [
    current,
    updateAttribute,
    directUploadAttachments,
    changeMeta,
    action,
    duplicatedUploadAttachments,
  ]);

  const handleGetErrors = (name: string) =>
    validateFormErrors(name, getFormValidationResult, hasErrorOnAttribute);

  return (
    <>
      <S.HandoutInformationStepWrapper>
        <S.RowWrapper>
          <S.Title>{t('new_handout_form.steps.information.title')}</S.Title>
          <Box mb="xs2">
            <ImageUploader
              label={t('new_handout_form.steps.information.image.label_text')}
              value={coverImage}
              onChange={handleImageChange}
            />
          </Box>

          <TextField
            label={t(
              'new_handout_form.steps.information.handout_title.label_text'
            )}
            placeholder={t(
              'new_handout_form.steps.information.handout_title.placeholder_text'
            )}
            value={newTitle}
            onChange={handleTitleChange}
            fullWidth
            errorMessage={t(
              `form_validation.${handleGetErrors('title').errorMessages}`
            )}
            error={handleGetErrors('title').hasError}
          />

          <Select
            label={t('new_handout_form.steps.information.category.label_text')}
            placeholder={t(
              'new_handout_form.steps.information.category.placeholder_text'
            )}
            value={category}
            options={handoutCategories.categories}
            onChange={handleCategoryChange}
            onActionSubmit={handleToggleNewCategoryModal}
            isSearchable
            noOptionsMessage={t(
              canWriteHandoutCategory
                ? 'new_handout_form.steps.information.category.empty_options_text'
                : 'new_handout_form.steps.information.category.empty_options_without_permition_text'
            )}
            fullWidth
            errorText={
              handleGetErrors('categoryId').hasError &&
              t('form_validation.required_field')
            }
          />

          {isActiveHandoutsAI && (
            <div>
              <AIButton onPress={() => setIsOpenAiModal(true)} />
            </div>
          )}

          <>
            <Field
              label={t(
                'new_handout_form.steps.information.description.label_text'
              )}
              quillProps={{
                onChange: (value: string) =>
                  description !== value &&
                  updateAttribute('description', value),
                value: description,
              }}
              type="textArea"
              useImage
            />

            {handleGetErrors('description').hasError && (
              <Text variant="label-regular-14" color="context.danger.default">
                {t('form_validation.required_field')}
              </Text>
            )}
          </>
          {isLoadingDuplicateHandout ? (
            <FileFieldSkeleton files={current?.attachments.length} />
          ) : (
            <FileField
              attributeName="directUploadAttachments"
              googleClientId={googleClientId}
              useGoogleDrive
            />
          )}

          <S.RequiredFields>
            {t('new_handout_form.steps.information.required_field_text')}
          </S.RequiredFields>
        </S.RowWrapper>
      </S.HandoutInformationStepWrapper>

      <NewCategoryModal.Root
        dataTestId="new-category-modal"
        isOpen={showNewCategoryModal}
        handleToggleModal={handleToggleNewCategoryModal}
        title={t('modals.create_category.title')}
      >
        <NewCategoryModal.Content>
          {t('modals.create_category.description')}
        </NewCategoryModal.Content>
        <NewCategoryModal.Actions>
          <Button variant="secondary" onClick={handleToggleNewCategoryModal}>
            {t('modals.create_category.close_button_text')}
          </Button>
          <Button onClick={() => handleNewCategory(newCategory)}>
            {t('modals.create_category.submit_button_text')}
          </Button>
        </NewCategoryModal.Actions>
      </NewCategoryModal.Root>

      {isActiveHandoutsAI && (
        <AIPromptProvider feature="handout">
          <AIPromptFormModal
            isOpen={isOpenAIModal}
            onClose={() => setIsOpenAiModal(false)}
            onInsert={(value) => updateAttribute('description', value)}
          />
        </AIPromptProvider>
      )}
    </>
  );
};

export default tabifyForWizard({
  title: '1 Informações',
  validations,
})(withAppContext(withFormContext(GeneralInformationStep)));
