import moment from 'moment';
import {
  EngageCounterStatus,
  Engagement,
  Event,
} from 'store/calendar/types/models';

import i18n from 'config/i18n';
import formatDatetime from 'core/utils/formatDatetime';
import { CalendarState } from 'store/calendar/types/store';

export const getDateSchedule = ({
  datetime,
  date,
}: {
  datetime: string;
  date: string | Date;
}) => {
  if (!datetime) return moment(date).startOf('day');

  const [hours, minutes] = datetime.split(':');
  return moment(date)
    .hours(parseInt(hours))
    .minutes(parseInt(minutes))
    .seconds(0);
};

export const hasInvalidDate = ({ time, currentOption }) => {
  if (!time?.length && !currentOption?.length) return false;

  const numValue = Number(time);
  if (numValue === 0) return 'invalid_message_text';

  const limits: Record<string, number> = {
    '1': 59, // Minute
    '2': 23, // Hour
    '3': 31, // Day
    '4': 2, // Week
  };

  if (currentOption in limits && numValue > limits[currentOption])
    return limits[currentOption];

  return false;
};

export const getRecipientTag = ({
  classrooms,
  studentProfiles,
  receiverType,
}: {
  classrooms: Event['relationships']['classrooms'];
  studentProfiles: Event['relationships']['studentProfiles'];
  receiverType: Event['attributes']['receiverType'];
}) => {
  const currentType = {
    classroom: classrooms,
    student: studentProfiles,
  };

  return {
    type: i18n.t(`calendar:common.event.receiver_type.${receiverType}`),
    recipients: currentType[receiverType].data.map(
      ({ attributes: { name } }) => ({
        name,
      })
    ),
  };
};

export const getSchedule = (eventAttributes: Event['attributes']): string => {
  const { allDay, endTime, startTime } = eventAttributes;

  const timeFormat = 'HH:mm';

  if (allDay) return i18n.t('calendar:list.card.schedule.all_day_text');

  if (!startTime)
    return i18n.t('calendar:list.card.schedule.to_be_defined_text');

  if (!endTime) return formatDatetime(startTime, timeFormat);

  return `${formatDatetime(startTime, timeFormat)} - ${formatDatetime(
    endTime,
    timeFormat
  )}`;
};

export const getTotalCount = (count: number) => {
  return count > 1 || count === 0
    ? i18n.t('calendar:list.total_count_multiple_text', {
        total: count,
      })
    : i18n.t('calendar:list.total_count_text', {
        total: count,
      });
};

export const hasActiveFilter = (
  filters: CalendarState['calendar']['event']['list']['filters']
): boolean => {
  return Boolean(
    filters?.activeSchoolTermId?.length ||
      filters?.classroomId?.length ||
      filters?.educationalStageId?.length ||
      filters?.headquarterId?.length ||
      filters?.status?.length ||
      filters?.name?.length
  );
};

export const getDateWithTime = (date: string, time: string) => {
  const formatedDate = moment(date);
  const formatedTime = moment(time);

  const combinedDate = formatedDate.set({
    hour: formatedTime.hour(),
    minute: formatedTime.minute(),
    second: formatedTime.second(),
  });

  return combinedDate.toISOString();
};

export const getEventDate = (eventAttributes: Event['attributes']) => {
  const i18nBase = 'calendar:details.tabs.informations.date_format_label';

  if (eventAttributes.allDay) {
    return formatDatetime(
      eventAttributes.startDate,
      i18n.t(`${i18nBase}.all_day_label`)
    );
  }

  if (!eventAttributes.startTime)
    return formatDatetime(
      eventAttributes.startDate,
      i18n.t(`${i18nBase}.not_defined_label`)
    );

  if (eventAttributes.startTime && !eventAttributes.endTime)
    return formatDatetime(
      getDateWithTime(eventAttributes.startDate, eventAttributes.startTime),
      i18n.t(`${i18nBase}.only_start_date_label`)
    );

  if (eventAttributes.startTime && eventAttributes.endTime)
    return formatDatetime(
      getDateWithTime(eventAttributes.startDate, eventAttributes.startTime),
      i18n.t(`${i18nBase}.all_time_label`)
    );
};

export const getEventApprovedStatus = (
  eventStatus: Event['attributes']['status']
): string => {
  const i18nBase = 'calendar:details.tabs.informations.actions';
  if (eventStatus === 'processing') return i18n.t(`${i18nBase}.approving`);

  if (eventStatus === 'sent' || eventStatus === 'approved')
    return i18n.t(`${i18nBase}.resend`);

  return i18n.t(`${i18nBase}.approve`);
};

export const getEngagementStatusTag = (
  status: Engagement['attributes']['status']
): 'positive' | 'negative' | 'warning' => {
  const variant: Record<string, 'positive' | 'negative' | 'warning'> = {
    yes: 'positive',
    no: 'negative',
    pending: 'warning',
  };

  return variant[status];
};

export const getEngagementPreviewIcon = (
  previewType: Engagement['attributes']['previewType']
): 'mobile' | 'desktop' | 'letter' => {
  const icon: Record<string, 'mobile' | 'desktop' | 'letter'> = {
    mobile: 'mobile',
    web: 'desktop',
    email: 'letter',
  };

  return icon[previewType];
};

export const getEngageCounterColor = (status: EngageCounterStatus) => {
  const colors: Record<EngageCounterStatus, string> = {
    sent: 'neutral.gray1',
    view: 'context.info.default',
    yes: 'context.success.default',
    no: 'context.danger.default',
    maybe: 'neutral.gray2',
    pending: 'context.warning.default',
  };

  return colors[status];
};

export const getEngageCounterTitle = (status: EngageCounterStatus): string => {
  const i18nBase = 'calendar:details.tabs.engagement.engage_counter';

  return i18n.t(`${i18nBase}.${status}`);
};
