import React, { createContext, useState, useCallback, useContext } from 'react';

import { trackEvent } from 'config/amplitude';

import {
  AIPromptProps,
  AIPromptContextType,
  GenerateTextParams,
} from './types';
import { ApiConstants, generatePrompt } from './helpers';

const AIPromptContext = createContext<AIPromptContextType>(null);

export const AIPromptProvider = ({ children, feature }: AIPromptProps) => {
  const [isGeneratingText, setIsGeneratingText] = useState(false);
  const [generatedText, setGeneratedText] = useState('');

  const generateText = useCallback(async (params: GenerateTextParams) => {
    if (!params.messagePurpose) return;

    trackEvent(`${feature}-generate-ai-request`);

    const prompt = generatePrompt(params);
    setIsGeneratingText(true);
    setGeneratedText('');

    try {
      const response = await fetch(ApiConstants.OPEN_AI_URL_COMPLETIONS, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [{ role: 'user', content: prompt }],
          model: 'gpt-3.5-turbo',
          temperature: 0.7,
          max_tokens: 500,
        }),
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar resposta da OpenAI');
      }

      const data = await response.json();
      setGeneratedText(data.choices[0]?.message?.content || '');
      trackEvent(`${feature}-generate-ai-success`);
    } catch (error) {
      trackEvent(`${feature}-generate-ai-error`, error);

      if (error.name === 'AbortError') {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error fetching AI response:', error);
      }
    } finally {
      setIsGeneratingText(false);
    }
  }, []);

  return (
    <AIPromptContext.Provider
      value={{
        generateText,
        isGeneratingText,
        generatedText,
        setGeneratedText,
        feature,
      }}
    >
      {children}
    </AIPromptContext.Provider>
  );
};

export const useAIPrompt = (): AIPromptContextType =>
  useContext(AIPromptContext);
