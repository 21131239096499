import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  TextField,
  Text,
  CardList,
  Box,
  FlexGridItem,
  Tag,
  SubtleIconButton,
} from '@agendaedu/ae-web-components';

import Loader from 'components/Loader';
import Toast from 'components/Toast';
import EmptyState from 'components/EmptyState';
import Currency from 'components/Currency';
import LinkModal from 'components/Payments/LinkModal';

import withFormContext from 'core/hoc/withFormContext';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import submissionAndReturnActions from 'store/edupay/submissionAndReturn/actions';

import * as S from './styles';

const BillsTab = () => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { isLoading, bills, filters, paginate } = useSelector(
    (state) => state.submissionAndReturn
  );

  const { fetchBills } = submissionAndReturnActions;

  const [title, setTitle] = useState('');
  const [linkModalMethod, setLinkModalMethod] = useState('');
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [linkModalLink, setLinkModalLink] = useState('');

  const debouncedFetchBills = _.debounce((value: string) => {
    dispatch(fetchBills({ ...filters, title: value }, 1));
  }, 500);

  const callFetchBills = useCallback(
    (value: string) => debouncedFetchBills(value),
    []
  );

  const hasMoreBills = () => {
    const numberOfItemsOnTheList =
      paginate.activePage * paginate.itemsCountPerPage;

    return numberOfItemsOnTheList < paginate.totalItemsCount;
  };

  const fetchMoreBills = () => {
    dispatch(fetchBills({ ...filters }, paginate.activePage + 1));
  };

  const handleBillTitleChange = ({ target }) => {
    callFetchBills(target.value);
    setTitle(target.value);
  };

  useEffect(() => {
    dispatch(fetchBills({ ...filters }, paginate.activePage));
  }, [dispatch, fetchBills]);

  const renderStatus = (status) => {
    const statusName = {
      pending: t(`submission_and_return.tab_bills.status.${status}`),
      delayed: t(`submission_and_return.tab_bills.status.${status}`),
      paid: t(`submission_and_return.tab_bills.status.${status}`),
      canceled: t(`submission_and_return.tab_bills.status.${status}`),
    };

    const statusVariant = {
      pending: 'warning',
      delayed: 'negative',
      paid: 'positive',
      canceled: 'neutral',
    };

    return (
      <Tag
        name={statusName[status]}
        variant={statusVariant[status]}
        size="small"
      />
    );
  };

  const handleToggleLinkModal = (method: string, link: string) => {
    setLinkModalMethod(method);
    setShowLinkModal(!showLinkModal);
    setLinkModalLink(link);
  };

  const getActions = (last_billet: string, last_pix: string) => {
    const actions = [];

    if (!!last_billet) {
      actions.push({
        as: 'button',
        title: t('submission_and_return.tab_bills.action.view_billet'),
        onClick: () => handleToggleLinkModal('billet', last_billet),
      });
    }

    if (!!last_pix) {
      actions.push({
        as: 'button',
        title: t('submission_and_return.tab_bills.action.view_pix'),
        onClick: () => handleToggleLinkModal('pix', last_pix),
      });
    }

    return actions;
  };

  const renderBillsList = () => {
    if (!isLoading && !bills?.length)
      return (
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.enrollmentEmptyStateFilterUrl}
          message={
            <Text variant="subtitle-medium-16" fontWeight={500}>
              {t('submission_and_return.tab_bills.empty_state_message')}
            </Text>
          }
        />
      );

    if (isLoading && !bills?.length) return <Loader />;

    return (
      <CardList
        data-testid="bills-list"
        pageStart={1}
        hasMore={!isLoading && hasMoreBills()}
        loadMore={fetchMoreBills}
        initialLoad={true}
        loader={<Loader />}
        mb={16}
      >
        {bills.map((item) => {
          const {
            id,
            attributes: {
              title,
              price_cents: priceCents,
              due_date: dueDate,
              status,
              last_billet,
              last_pix,
            },
          } = item;

          return (
            <React.Fragment key={id}>
              <FlexGridItem>
                <Box paddingRight={40} data-testid="title">
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {t('submission_and_return.tab_bills.labels.title')}
                  </Text>
                  <S.CustomTextName
                    variant="subtitle-medium-16"
                    color="neutral.gray1"
                  >
                    {title}
                  </S.CustomTextName>
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40} data-testid="price">
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {t('submission_and_return.tab_bills.labels.value')}
                  </Text>
                  <Text variant="subtitle-medium-16" color="neutral.gray1">
                    <Currency value={priceCents} />
                  </Text>
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40} data-testid="date">
                  <Text variant="subtitle-medium-12" color="neutral.gray2">
                    {t('submission_and_return.tab_bills.labels.date')}
                  </Text>
                  <Text variant="subtitle-medium-16" color="neutral.gray1">
                    {dueDate}
                  </Text>
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box paddingRight={40} data-testid="status">
                  {renderStatus(status)}
                </Box>
              </FlexGridItem>

              <FlexGridItem>
                <Box position="absolute" top={20} right={16}>
                  <SubtleIconButton
                    data-testid="button-actions"
                    actions={getActions(last_billet, last_pix)}
                    align="right"
                  />
                </Box>
              </FlexGridItem>
            </React.Fragment>
          );
        })}
      </CardList>
    );
  };

  return (
    <div data-testid="bill-tab">
      <S.WrapperFilters>
        <TextField
          data-testid="input-search"
          value={title}
          onChange={handleBillTitleChange}
          icon="search"
          placeholder={t('submission_and_return.tab_bills.filters.search_bill')}
          width={226}
        />
      </S.WrapperFilters>

      <S.WrapperNumberFiles>
        <Text
          data-testid="number-files"
          variant="subtitle-medium-14"
          lineHeight="md"
          mb={0}
          color={'neutral.gray2'}
        >
          {t('submission_and_return.tab_bills.amount_bills', {
            totalItemsCount: paginate.totalItemsCount,
          })}
        </Text>
      </S.WrapperNumberFiles>

      <Box mt={16}>{renderBillsList()}</Box>

      <LinkModal
        method={linkModalMethod}
        showModal={showLinkModal}
        toggleModal={handleToggleLinkModal}
        link={linkModalLink}
      />

      <Toast />
    </div>
  );
};

export default withFormContext(BillsTab);
